/* You can add global styles to this file, and also import other style files */

@import "~@angular/cdk/overlay-prebuilt.css";


/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand {
  height: 75px;
}

.navbar .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 5px 0;
  color: var(--dark);
  font-weight: 500;
  outline: none;
}

/* top level */
.navbar-nav > .dropdown:hover > .position-relative > .dropdown-menu {
  top: 0;
  right: 0;
}

/* sub levels */
.navbar-nav .position-relative .position-relative .dropdown-menu {
  top: 10px;
  right: 50%;
  margin-top: -1px;
}

.navbar-nav > .dropdown > .position-relative > .dropdown-menu,
.navbar-nav .position-relative .dropdown > .position-relative > .dropdown-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: .5s;
}

.navbar-nav > .dropdown:hover > .position-relative > .dropdown-menu,
.navbar-nav .position-relative .dropdown:hover > .position-relative > .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transition: .5s;
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  /*display: none;  Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  }

  .table-hover> tbody> tr:hover{
    background-color:lightblue;
}

.clickable {
  cursor: pointer;
}

body{
  padding-top: 50px;
}
